import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './Blackjack.css';

import Navbar from '../../Components/Navbar/Navbar';
import Card from '../../Components/Card';

import Instructions from '../../Components/Instructions-Popup/Instructions';

import { FaQuestion } from 'react-icons/fa';



const socket = io('http://localhost:4000');

const SUITS = ['H', 'C', 'D', 'S'];
const VALUES = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

let blank_card = require('../../Images/cards/blank_card.png');
const icon = require('./icon.png');
const player_img = require('./player.png');


const generateDeck = () => {
    const deck = [];

    for (const suit of SUITS) {
        for (const value of VALUES) {
            deck.push({ suit, value });
        }
    }
    return deck;
};

const shuffleDeck = (deck) => {
    const shuffledDeck = [...deck];

    for (let i = shuffledDeck.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledDeck[i], shuffledDeck[j]] = [shuffledDeck[j], shuffledDeck[i]];
    }

    return shuffledDeck;
};

const generateGameCode = () => {
    // Uncomment this line if you install uuid
    // const code = uuidv4().substring(0, 6);

    // For demo purposes, you can use this instead
    const code = Math.floor(100000 + Math.random() * 900000).toString();
    return code;
}

const Blackjack = () => {
    const [playerHand, setPlayerHand] = useState([]);
    const [dealerHand, setDealerHand] = useState([]);
    const [bet, setBet] = useState(0);
    const [deck, setDeck] = useState(() => shuffleDeck(generateDeck()));
    const [topCardIndex, setTopCardIndex] = useState(7);
    const [players, setPlayers] = useState([]);
    const [isInstructionsOpen, setIsInstructionsOpen] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [animatingCard, setAnimatingCard] = useState(null);
    const [gameCode, setGameCode] = useState(generateGameCode());

    useEffect(() => {
        // Event to handle initial hand dealt to player
        socket.on('dealPlayer', (hand) => {
            setPlayerHand(hand);
        });

        // Event to handle initial hand dealt to dealer
        socket.on('dealDealer', (hand) => {
            setDealerHand(hand);
        });
    }, []);

    // Function to place a bet
    const placeBet = (amount) => {
        setBet(amount);
        socket.emit('placeBet', amount);
    };

    // Function to hit
    const hit = () => {
        socket.emit('hit');
    };

    // Function to stand
    const stand = () => {
        socket.emit('stand');
    };

    const handleDeckClick = () => {
        if (topCardIndex < 52) {
            setTopCardIndex(topCardIndex + 1);
        }
    };
    
    const createGame = () => {
        const initialGameState = "waiting_for_players";
        socket.emit('createGame', { gameCode, gameState: initialGameState });
    };

    useEffect(() => {
        createGame();
    }, []);


    const startGame = () => {
        socket.emit('startGame', { gameCode });
        // ...
        setGameStarted(true);
        // Place your game starting logic here
        dealCardsToPlayers();
    };

    const dealCardsToPlayers = () => {
        let currentCardIndex = topCardIndex;
        let currentPlayerIndex = 0;
        let rounds = 0; // To keep track of how many rounds of dealing we've done
        const newPlayers = [...players];
        let newDealerHand = [...dealerHand];

        const dealNextCard = () => {
            if (rounds < 2) {
                if (currentPlayerIndex < newPlayers.length) {
                    // Get the DOM element of the current player
                    const playerElement = document.querySelector(`.bj-player:nth-child(${currentPlayerIndex + 1})`);
                    const { top, left } = playerElement.getBoundingClientRect();

                    // Set the position for the animating card
                    setAnimatingCard({ top, left });

                    setTimeout(() => {
                        // Add the card to the player's hand
                        newPlayers[currentPlayerIndex].hand.push(deck[currentCardIndex]);
                        setPlayers(newPlayers);

                        // Move to the next player and card
                        currentPlayerIndex++;
                        currentCardIndex++;
                        setTopCardIndex(currentCardIndex);

                        // Reset the animating card and deal the next card
                        setAnimatingCard(null);
                        dealNextCard();
                    }, 1000);  // 1s matches the transition duration in the CSS
                } else {
                    // Deal card to the dealer
                    newDealerHand.push(deck[currentCardIndex]);
                    setDealerHand(newDealerHand);

                    // Move to the next card
                    currentCardIndex++;
                    setTopCardIndex(currentCardIndex);

                    // Reset the animating card
                    setAnimatingCard(null);

                    // Reset the player index and increase the round
                    currentPlayerIndex = 0;
                    rounds++;

                    dealNextCard();
                }
            }
        };

        dealNextCard();
    };

    useEffect(() => {
        // When a new player joins, the server should emit 'updateGameState'
        socket.on("updateGameState", (newGameState) => {
            // Assume newGameState contains a list of players
            setPlayers(newGameState.players);
        });
    }, []);

    return (
        <div className="blackjack">
            <Navbar />
            <div>
                <div className="bj-title-row">
                    <h1 className="bj-title">Blackjack</h1>
                    <FaQuestion className="instructions-btn-bj" onClick={() => setIsInstructionsOpen(true)} />
                </div>
                {animatingCard && (
                    <img
                        src={require('../../Images/cards/back_of_card.png')}
                        className={`card-sliding card-start-position`}
                        style={{
                            top: `${animatingCard.top}px`,
                            left: `${animatingCard.left}px`
                        }}
                    />
                )}
                {isInstructionsOpen && <Instructions gameTitle="Ride The Bus (4 Card) Instructions" subheader="Guess correctly or drink!" icon={icon} instructionsText="" onClose={() => setIsInstructionsOpen(false)} />}
                <div className="bj-icon-row">
                    <img className="bj-icon" src={icon} />
                </div>
                {gameCode && <div className="bj-game-code">Game Code: {gameCode}</div>}
                <div className="bj-dealer">
                    <span className="bj-dealer-name">Dealer</span>

                    <div className="bj-deck">
                        <img
                            src={require('../../Images/cards/back_of_card.png')}
                            alt="Card Back"
                            style={{
                                width: '80px',
                                height: '120px',
                                margin: '2px',
                                borderRadius: '5px',
                                boxShadow: '0 0 5px 2px rgba(0,0,0,0.25)'
                            }}
                            onClick={handleDeckClick}
                        />
                    </div>
                    {!gameStarted && (
                        <button onClick={startGame}>Start</button>
                    )}

                    <div className="bj-dealer-hand">
                        {dealerHand.map((card, cardIndex) => (
                            <img
                                key={cardIndex}
                                src={cardIndex === 1 ? require(`../../Images/cards/${card.value}${card.suit}.png`) : require('../../Images/cards/back_of_card.png')}
                                alt="Card"
                                style={{
                                    width: '60px',
                                    height: '80px',
                                    margin: '2px',
                                    borderRadius: '5px',
                                    boxShadow: '0 0 5px 2px rgba(0,0,0,0.25)'
                                }}
                            />
                        ))}
                    </div>
                </div>

                <div className="bj-player-row">
                    {players.map((player, index) => (
                        <div
                            className={`bj-player ${player.isTurn ? 'bj-player-active' : ''}`}
                            key={index}
                        >
                            <img className="bj-player-img" src={player_img} alt={`${player.name}`} />
                            <div className="bj-player-info">
                                <span>{player.name}</span>
                                <span>Bet: {player.bet}</span>
                                <span>Money: {player.money}</span>
                            </div>
                            <div className="bj-player-hand">
                                {Array.from({ length: player.hand.length }).map((_, cardIndex) => (
                                    <img
                                        key={cardIndex}
                                        src={require('../../Images/cards/back_of_card.png')}
                                        alt="Card Back"
                                        style={{
                                            width: '60px',
                                            height: '80px',
                                            margin: '2px',
                                            borderRadius: '5px',
                                            boxShadow: '0 0 5px 2px rgba(0,0,0,0.25)'
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>




            </div>
        </div>
    );
};

export default Blackjack;
