import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './BlackjackPlayer.css';
import { useSearchParams } from "react-router-dom";

const socket = io('http://localhost:4000');

const BlackjackPlayer = () => {
    const [searchParams] = useSearchParams();
    const [name, setName] = useState(searchParams.get('name'));
    const [money, setMoney] = useState(0);
    const [bet, setBet] = useState(0);
    const [hand, setHand] = useState([]);

    console.log("rendered")
    useEffect(() => {
        // Subscribe to socket events
        socket.on('updateName', setName);
        socket.on('updateMoney', setMoney);
        socket.on('updateBet', setBet);
        socket.on('updateHand', setHand);

        // Cleanup
        return () => {
            socket.off('updateName');
            socket.off('updateMoney');
            socket.off('updateBet');
            socket.off('updateHand');
        };
    }, []);

    useEffect(() => {
        // Send join game event
        console.log("Join game effect running");
        socket.emit('joinGame', { gameCode: searchParams.get('gameCode'), playerData: { name } });
    }, []);


    const onHit = () => {
        socket.emit('hit');
    };

    const onStand = () => {
        socket.emit('stand');
    };

    const onDouble = () => {
        socket.emit('double');
    };

    const onSplit = () => {
        socket.emit('split');
    };

    return (
        <div className="bj-player">
            <div className="bj-player-info">
                <h2>{name}</h2>
                <p>Money: ${money}</p>
                <p>Bet: ${bet}</p>
            </div>
            <div className="bj-player-hand">
                {hand.map((card, index) => (
                    <img
                        key={index}
                        src={require(`../../Images/cards/${card.value}${card.suit}.png`)}
                        alt={`${card.value}${card.suit}`}
                        className="bj-card"
                    />
                ))}
            </div>
            <div className="bj-player-actions">
                <button onClick={onHit}>Hit</button>
                <button onClick={onStand}>Stand</button>
                <button onClick={onDouble}>Double</button>
                {hand.length === 2 && hand[0].value === hand[1].value && (
                    <button onClick={onSplit}>Split</button>
                )}
            </div>
        </div>
    );
};

export default BlackjackPlayer;
