import React, { useState } from 'react';
import './BlackjackForm.css';
import io from 'socket.io-client';
import { useNavigate } from "react-router-dom";

const socket = io('http://localhost:4000');

const BlackjackForm = () => {
  const [joinGame, setJoinGame] = useState(false);
  const [gameCode, setGameCode] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const handleStartGame = () => {
    // Put your start game logic here
    navigate('/blackjack-game');
  };

  const handleJoinGame = () => {
    setJoinGame(true);
  };

  const handleJoinGameSubmit = (e) => {
    e.preventDefault();
    // Put your join game logic here using gameCode
    navigate('/blackjack-player?name=' + name + '&gameCode=' + gameCode);
  };

  return (
    <div className="blackjack-form-overlay">
      <div className="blackjack-form">
        <h2>Welcome to Blackjack</h2>
        
        <button onClick={handleStartGame}>Start Game</button>
        <button onClick={handleJoinGame}>Join Game</button>
        {joinGame && (
          <form className="join-game-section" onSubmit={handleJoinGameSubmit}>
            <input
              type="text"
              placeholder="Enter game code"
              value={gameCode}
              onChange={(e) => setGameCode(e.target.value)}
            />
            <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <button type="submit">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default BlackjackForm;
